import React, { useState, useEffect } from 'react';
import { Col, Text, CMSLayout, Row, Button, Grid, Input, useUIState } from 'components';
import { IScreen } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { COLOR, SCREEN } from 'const';
import { ActivityIndicator } from 'react-native';
import { VarHelper } from 'helpers';
import Select from 'react-select';
import { AntDesign, Ionicons } from '@expo/vector-icons';

const AdminCollectionsCreate: IScreen = () => {
  const { navigation, route } = useNavFunc();
  // @ts-ignore
  const { id } = route.params || {};
  const [{ loading: singleLoading }, setSingleUI] = useUIState();
  const [collection, setCollection] = useState<any>();
  const [moneys, setMoneys] = useState<Array<any>>([]);
  const [listQuyUoc, setListQuyUoc] = useState([]);
  
  const [singleAddedDone, setSingleAddedDone] = useState(false);
  const [single, setSingle] = useState({
    short: '',
    seri: '',
    displayValue: '',
    currency: '',
    code: '',
    price: 0,
  });
  const listQuyUocOptions = listQuyUoc.map(v => ({ label: v.short, value: v.displayValue, currency: v.currency }));

  const singleAdd = async () => {
    if (!single.short) return alert('Vui lòng chọn mệnh giá');
    if (!single.code) return alert('Vui lòng điền kí tự');
    if (!single.seri) return alert('Vui lòng điền seri');
    if (!single.price) return alert('Vui lòng điền giá bán');
    const year = single.seri.slice(single.seri.length - 4, single.seri.length);
    const dateAndMonth = single.seri.slice(0, single.seri.length - 4);
    if (isNaN(+year) || isNaN(+dateAndMonth)) {
      return alert('Vui lòng kiểm tra lại seri');
    }
    setSingleUI({ loading: true });
    const toBeAddedMoney = {
      ...single,
      year,
      dateAndMonth,
    };
    delete toBeAddedMoney.price;
    const checkExisted = await Store.Api.Money.list({
      code: toBeAddedMoney.code,
      seri: toBeAddedMoney.seri,
      short: toBeAddedMoney.short,
    });
    if (checkExisted.data.data.list.length > 0) {
      setSingleUI({ loading: false });
      const newMoneys = moneys.slice();
      newMoneys.push({
        ...checkExisted.data.data.list[0],
        price: single.price
      });
      setMoneys(newMoneys);
      setSingle({
        short: '',
        seri: '',
        displayValue: '',
        currency: '',
        code: '',
        price: 0
      });
      return;
    }
    const res = await Store.Api.Money.upsert(toBeAddedMoney);
    if (res.data.error) {
      alert(res.data.error);
    } else {
      setSingleAddedDone(true);
      setTimeout(() => {
        setSingleAddedDone(false);
      }, 1000);
      const newMoneys = moneys.slice();
      newMoneys.push({
        ...res.data.data,
        price: single.price,
      });
      setMoneys(newMoneys);
      setSingle({
        short: '',
        seri: '',
        displayValue: '',
        currency: '',
        code: '',
        price: 0
      });
    }
    setSingleUI({ loading: false });
  }

  useEffect(() => {
    (async () => {
      const res = await Store.Api.QuyUoc.list({});
      if (res.data.success) {
        setListQuyUoc(res.data.data);
      }
    })();
  }, []);

  const getData = async () => {
    const res = await Store.Api.Collection.detail({ id });
    if (res.data.success) {
      setCollection(res.data.data);
      setMoneys(res.data.data.moneys);
    }
  };

  useEffect(() => {
    if (id === 'new') return;
    getData();
  }, [id]);

  const singleFormProps = (label) => {
    return {
      value: single[label],
      onChange: (newVal) => {
        setSingle({
          ...single,
          [label]: newVal
        })
      }
    }
  }

  const upsert = async () => {
    const obj = {
      id,
      name: collection.name,
      slug: collection.slug || '',
      moneys: moneys,
    };
    if (id === 'new') delete obj.id;
    const res = await Store.Api.Collection.upsert(obj);
    if (res.data.error) return alert(res.data.error);
    alert('Thao tác thành công');
    navigation.reset({
      index: 0,
      routes: [{ name: SCREEN.AdminCollections }],
    });
  }

  return (
    <CMSLayout requireAuthen>
      <Col>
        <Grid xs='100%' md='100%'>
          <Col p1 m1>
            <Text mb1>Tên bộ sưu tập</Text>
            <Input
              value={collection?.name || ''}
              onChange={(v) => {
                setCollection({
                  ...collection,
                  name: v
                })
              }}
              backgroundColor='white'
            />
          </Col>
        </Grid>
      </Col>
      <Row flexWrap={'wrap'} mb2 mh2>
        <Text m0 bold>Thêm 1 tờ tiền:</Text>
        <Text m0>Ký tự:</Text>
        <Input {...singleFormProps('code')} width={150} borderWidth={0} borderRadius={0} borderBottomWidth={1} />
        <Text m0 width={70}>Mệnh giá:</Text>
        {/* <Input width={50} borderWidth={0} borderRadius={0} borderBottomWidth={1} /> */}
        <Select
          value={listQuyUocOptions.find(item => item.label === single.short)}
          options={listQuyUocOptions}
          styles={{
            valueContainer: (style) => ({ ...style, height: 30, minHeight: 30, fontSize: 12 }),
            indicatorsContainer: (style) => ({ ...style, height: 30, minHeight: 30 }),
            control: (style) => ({ ...style, width: 200, margin: 5, height: 30, minHeight: 30, borderRadius: 15, boxShadow: 'none' }),
          }}
          onChange={newValue => {
            console.log('onChange', newValue);
            setSingle({ ...single, short: newValue.label, displayValue: newValue.value, currency: newValue.currency });
          }}
          menuPortalTarget={document.body}
        />
        <Text m0 width={50}>Seri:</Text>
        <Input {...singleFormProps('seri')} width={100} borderWidth={0} borderRadius={0} borderBottomWidth={1} />
        <Text m0 width={50}>Giá:</Text>
        <Input {...singleFormProps('price')} width={100} borderWidth={0} borderRadius={0} borderBottomWidth={1} />
        <Button
          isLoading={singleLoading}
          text={singleAddedDone ? 'Thêm thành công' : 'Thêm'}
          onPress={singleAdd}
          height={30}
          borderRadius={15}
          width={120}
          ml1 mt1
        />
      </Row>
      <Row m1 p1 backgroundColor={COLOR.MAIN} round1>
        <Col flex1>
          <Text bold colorWhite>Kí tự</Text>
        </Col>
        <Col flex1>
          <Text bold colorWhite>Mệnh giá</Text>
        </Col>
        <Col flex1>
          <Text bold colorWhite>Seri</Text>
        </Col>
        <Col flex1>
          <Text bold colorWhite>Giá bán</Text>
        </Col>
        <Col flex1>
          
        </Col>
      </Row>
      {moneys.map((val, i) => {
        return (
          <Col m1 p1 backgroundColor={'rgba(255,255,255,0.8)'} round1 key={val.id}>
            <Row mb1>
              <Col flex1>
                <Text>{val.code}</Text>
              </Col>
              <Col flex1>
                <Text>{val.short}</Text>
              </Col>
              <Col flex1>
                <Text>{val.seri}</Text>
              </Col>
              <Col flex1>
                <Text>{VarHelper.formatMoney(val.price)}</Text>
              </Col>
              <Row flex1>
                {/* <Col>
                  <AntDesign name="edit" size={24} color="black" />
                </Col> */}
                <Col ml1
                  onPress={() => {
                    const shouldDelete = confirm('Bạn có chắc muốn xóa tờ tiền này k?');
                    if (!shouldDelete) return;
                    setMoneys(moneys.filter(v => v.id !== val.id));
                  }}
                >
                  <Ionicons name="md-trash-bin-outline" size={24} color="black" />
                </Col>
              </Row>
            </Row>
          </Col>
        );
      })}
      {Boolean(moneys.length > 0 || id !== 'new') && (
        <Button
          m1
          solid
          text={id === 'new' ? 'Tạo' : 'Lưu'}
          onPress={upsert}
        />
      )}
    </CMSLayout>
  )
};

AdminCollectionsCreate.routeInfo = {
  title: 'Bộ sưu tập',
  path: '/admin/collections/:id',
};

export default AdminCollectionsCreate;