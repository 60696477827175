import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Col, Text, Header, Input, Row, Button, useUIState, Grid, MoneyImage, RatioCol, ApolloEffect, modal, TouchField, HomeHeader } from 'components';
import { IScreen } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { SCREEN, SITE_INFO, COLOR, IS_TIENNAMSINH_NET } from 'const';
import { ActivityIndicator, Image, ScrollView } from 'react-native';
import { useDynamicResponsiveValue } from 'quickly-react';
import { AntDesign, FontAwesome5, EvilIcons, MaterialIcons, Entypo, FontAwesome } from '@expo/vector-icons';
import { ASSETS } from 'assets';
import { FacebookProvider, Page, CustomChat } from 'react-facebook';
import SearchForm from './Home.SearchForm';
import { SubmitOrder } from './Home.SubmitOrder';
import { TimeHelper, VarHelper } from 'helpers';
import Swal from 'sweetalert2'

const showError = (message) => {
  Swal.fire({
    title: 'Có lỗi xảy ra!',
    text: message,
    icon: 'error',
    confirmButtonText: 'OK'
  })
}

const showAlert = message => {
  Swal.fire(
    'Thành công!',
    message,
    'success'
  )
}

const CollectionDetail: IScreen = () => {
  const { navigate, route } = useNavFunc();
  const { id }: any = route.params || {};
  const [listSearch, setListSearch] = useState([]);
  const [carts, setCarts] = useState([]);
  const [message, setMessage] = useState('');

  const listQuyUoc = useRef([]);
  const rV = useDynamicResponsiveValue(['xs', 'md']);
  const breakpoint = rV({ xs: 'xs', md: 'md' });

  const [{ loading: form1Loading }, setForm1UI] = useUIState();
  const [{ loading: form2Loading }, setForm2UI] = useUIState();

  useEffect(() => {
    if (!id) return;
    Store.Api.Collection.detail({ id })
    .then((res => {
        if (res.data.success) {
            setListSearch(res.data.data.moneys);
        }
    }))
  }, [id]);

  useEffect(() => {
    (async () => {
      const res = await Store.Api.QuyUoc.list({});
      if (res.data.data) {
        listQuyUoc.current = res.data.data;
      }
    })();
  }, []);

  const showMoneyModal = (val) => {
    modal.show(
      <Col bgWhite round1 shadow p2 width={rV({ xs: 360, md: window.innerWidth * 0.8 })}>
        <MoneyImage {...val} />
      </Col>
    )
  };

  const withDisplayOrder = (list) => {
    const seriSorted = list.sort((a, b) => a.seri > b.seri ? 1 : -1);
    if (!SITE_INFO.MONEY_DISPLAY_ORDER) return seriSorted;

    const parts = SITE_INFO.MONEY_DISPLAY_ORDER.split(',').map(v => v.trim());
    let results = [];
    let excludeIndexs = [];
    const higherPriorityArr = parts.map(p => seriSorted.filter((val, index) => {
      if (val.short === p) {
        excludeIndexs.push(index);
        return true;
      }
      return false;
    }));
    const otherArr = seriSorted.filter((val, index) => !excludeIndexs.includes(index));

    higherPriorityArr.forEach(arr => {
      results = [...results, ...arr];
    })
    return [
      ...results,
      ...otherArr,
    ];
  };

  const renderMainContent = () => {
    return (
      <Col flex1 justifyContent='center' backgroundColor={'#E9E9F0'}>
        <ScrollView
          contentContainerStyle={{
            flex: 1,
            justifyContent: 'center',
          }}
        >
          <Col p2 middle>
            <HomeHeader />
          </Col>
          {listSearch.length > 0 ? (
            <Grid xs='100%' md='33.33%' p1
              paddingBottom={carts.length > 0 ? 60 : 10}
              middle
            >
              {listSearch.map((val, i) => {
                const isInCart = !!carts.find(item => item.id === val.id);
                const price = val.price;
                return (
                  <Col key={val.id} m1 p1 round1 overflow={'hidden'} backgroundColor='white' shadow>
                    <ApolloEffect>
                      <RatioCol width={'100%'} ratio={316 / 160} backgroundColor={'rgba(0,0,0,0.3)'}>
                        <Col flex1 middle p1>
                          <MoneyImage
                            {...val}
                          />
                        </Col>
                      </RatioCol>
                    </ApolloEffect>
                    <Row mv1 stretch>
                      <Col flex1>
                        <Row mb1>
                          <Col width={30} height={30} justifyContent='center'>
                            <FontAwesome5 name="money-bill" size={24} color={'rgba(0,0,0,0.3)'} />
                          </Col>
                          <Col ml2 flex1>
                            <Text bold fontSize={14}>{val.code} {val.seri}</Text>
                          </Col>
                        </Row>
                        <Row>
                          <Col width={30} height={30} justifyContent='center'>
                            {/* <MaterialIcons name="attach-money" size={24} color={'rgba(0,0,0,0.3)'} /> */}
                            <Text color={'rgba(0,0,0,0.7)'} fontSize={15}>{val.currency.toLowerCase()}</Text>
                          </Col>
                          <Col ml2 flex1>
                            <Text bold color="red" fontSize={18}>{VarHelper.formatMoney(price)}</Text>
                          </Col>
                        </Row>
                      </Col>
                      <Col flex1 alignItems={'flex-end'} justifyContent='flex-end'>
                        {/* <Text caption mb1>thêm vào giỏ hàng</Text> */}
                        {breakpoint !== 'xs' && (
                          <TouchField width={30} height={30} borderRadius={15} middle mb2
                            onPress={() => {
                              showMoneyModal({
                                ...val,
                                price
                              });
                            }}
                          >
                            <FontAwesome name="expand" size={16} color={'rgba(0,0,0,0.6)'} />
                          </TouchField>
                        )}
                        <Button
                          text={isInCart ? 'Đã chọn' : 'Chọn'}
                          width={100}
                          height={30}
                          borderRadius={15}
                          iconLeft={isInCart ? (
                            <AntDesign name="check" size={24} color="white" />
                          ) : (
                            <EvilIcons name="cart" size={24} color="white" />
                          )}
                          backgroundColor={isInCart ? SITE_INFO.BRAND_COLOR : 'red'}
                          bgHovered={SITE_INFO.BRAND_COLOR}
                          onPress={() => {
                            if (isInCart) setCarts(carts.filter(item => item.id !== val.id));
                            else {
                              setCarts([...carts, { ...val, price }]);
                            }
                          }}
                        />
                      </Col>
                    </Row>

                  </Col>
                );
              })}
            </Grid>
          ) : (
            Boolean(!!message) && (
              <Col p2 middle mb3>
                <Text bold>{message}</Text>
              </Col>
            )
          )}
        </ScrollView>
        {carts.length > 0 && (
          <Row
            height={50} shadow
            bottom={0} left={0} right={0}
            backgroundColor='white'
            borderTopWidth={2}
            borderTopColor={SITE_INFO.BRAND_COLOR}
            middle
            // @ts-ignore
            position='fixed'
          >
            <Entypo name="shopping-cart" size={24} color={SITE_INFO.BRAND_COLOR} />
            <Text center ml2>Đã chọn: <Text bold>{carts.length}</Text></Text>
            <Button
              outline
              borderColor={'red'}
              text='Kiểm tra đơn hàng'
              width={200}
              height={30}
              borderRadius={15}
              fontStyle={{
                color: 'red',
                fontWeight: 'bold',
              }}
              bgHovered={'rgba(255, 0, 0, 0.2)'}
              ml2
              onPress={() => {
                modal.show(
                  <SubmitOrder
                    carts={carts}
                    setCarts={setCarts}
                    onPlaceOrderDone={() => {
                      
                    }}
                    ctv={''}
                  />
                )
              }}
            />
          </Row>
        )}
      </Col>
    )
  };

  return (!!SITE_INFO.FB_APP_ID) ? (
    <FacebookProvider appId={SITE_INFO.FB_APP_ID} chatSupport>
      {renderMainContent()}
      {SITE_INFO.FB_PAGE_ID && (
        <CustomChat pageId={SITE_INFO.FB_PAGE_ID} themeColor={SITE_INFO.BRAND_COLOR} />
      )}
    </FacebookProvider>
  ) : renderMainContent();
  // return renderMainContent();
}



CollectionDetail.routeInfo = {
  title: SITE_INFO.NAME,
  path: '/collection/:id/:slug',
};

export default CollectionDetail;