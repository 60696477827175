import Request from '../Request.utils'
export interface IUpsertRequestBody {
  id?: string;
  moneys?: any[];
  name?: string;
  slug?: string;
}
export interface IListRequestQuery {
  page?: number;
}
export interface IDeleteRequestParams {
  id: string;
}
export interface IDetailRequestParams {
  id: string;
}


class CollectionAPI {
  upsert = async (body: IUpsertRequestBody) => {
    const res = await Request.call('/api/collection', 'POST', undefined, undefined, body, );
    return res;
  }
  list = async (query: IListRequestQuery) => {
    const res = await Request.call('/api/collection', 'GET', undefined, query, undefined, );
    return res;
  }
  delete = async (params: IDeleteRequestParams) => {
    const res = await Request.call('/api/collection/:id', 'DELETE', params, undefined, undefined, );
    return res;
  }
  detail = async (params: IDetailRequestParams) => {
    const res = await Request.call('/api/collection/:id', 'GET', params, undefined, undefined, );
    return res;
  }
}
export default new CollectionAPI()