import { LightenDarkenColor } from 'lighten-darken-color';
export * from './screens';
import Store from 'store'

export const FONT = {
  defaultFont: 'Raleway, sans-serif',
  bold: 'Raleway, sans-serif',
  light: 'Raleway, sans-serif',
};

export const IS_TIENNAMSINH_NET = window.location.host === 'tiennamsinh.net';

export const COLOR = {
  FONT: 'black',
  MAIN: '#003846',
  // MAIN: 'rgb(45, 148, 31)',
  BORDER: '#FBFBFB',
  BLUE: '#005EB8',
  YELLOW: '#FFF100',
  GREY_BG: '#F5F5F5',
  GREEN: '#2D941F',
  BG: '#FFDFD6',
  GREY: '#B9B9B9',
  GREY_LIGHT: '#F0F4F6',
}

export const CTV = {
  thegioitien: {
    BANK_NUMBER: '1111003399999',
    BANK_HOLDER_NAME: 'VI VĂN CHÍNH',
    BANK_NAME: 'Vietcombank',
  },
  tienlixi: {
    BANK_NUMBER: '1111003399999',
    BANK_HOLDER_NAME: 'VI VĂN CHÍNH',
    BANK_NAME: 'Vietcombank',
  },
  maihuuqui: {
    BANK_NUMBER: '0911000047472',
    BANK_HOLDER_NAME: 'MAI HUU QUI',
    BANK_NAME: 'Vietcombank',
  },
  daohuutua: {
    BANK_NUMBER: '0868999036',
    BANK_HOLDER_NAME: 'ĐÀO HỮU TỰA',
    BANK_NAME: 'Vietcombank',
  },
}

const BRAND_COLOR = '#2D941F';

class SiteInfo {
  NAME = '';
  DESCRIPTION = '';
  BRAND_COLOR = BRAND_COLOR;
  SDT = '';
  EMAIL = '';
  FB = '';
  BANK_NUMBER = '';
  BANK_HOLDER_NAME = '';
  BANK_NAME = '';
  SHIPPING_FEE_COD = 0;
  SHIPPING_FEE_BANK = 0;
  ADDRESS='';
  COVER='';
  MONEY_DISPLAY_ORDER='';
  FB_APP_ID = '';
  FB_PAGE_ID = '';
  FROM_BRAND_COLOR = num => LightenDarkenColor(this.BRAND_COLOR, num);

  ready=false;

  constructor() {
    this.getSiteInfo();
  }

  async getSiteInfo() {
    const res = await Store.Api.Data.list({
      type: 'info'
    }, {
      'field1': 'MAIN_INFO'
    });
    if (res.data.data.length > 0) {
      const data = res.data.data[0];
      this.BRAND_COLOR = data.data.brandColor;
      this.NAME = data.data.name;
      this.DESCRIPTION = data.data.description;
      this.SDT = data.data.sdt;
      this.EMAIL = data.data.email;
      this.FB = data.data.fb;
      this.BANK_NUMBER = data.data.bankNumber;
      this.BANK_HOLDER_NAME = data.data.bankHolderName;
      this.BANK_NAME = data.data.bankName;
      this.SHIPPING_FEE_COD = +data.data.shippingFeeCOD;
      this.SHIPPING_FEE_BANK = +data.data.shippingFeeBank;
      this.ADDRESS = data.data.address;
      this.COVER = data.data.coverUrl;
      this.MONEY_DISPLAY_ORDER = data.data.moneyDisplayOrder;
      this.FB_APP_ID = data.data.fbAppID;
      this.FB_PAGE_ID = data.data.fbPageID;
      COLOR.MAIN = data.data.brandColor;
    }
    this.ready = true;
    console.log('getSiteInfo done');
  }
  onReady() {
    if (this.ready) return Promise.resolve();
    return new Promise((resolve) => {
      const _interval = setInterval(() => {
        console.log('this.ready', this.ready);
        if (this.ready) {
          clearInterval(_interval);
          resolve(undefined);
        }
      }, 500);
    });
  }
}
export const SITE_INFO = new SiteInfo();